import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Media from '../../helpers/mediaTemplates';

import Perk from './Perk';

const getPerkForImageName = imageName => {
  switch (imageName) {
    case '1-money':
      return {
        title: 'Flexible & Affordable Rates',
        description:
          'Great rates with no administration, card or hidden fees. No upselling.',
      };
    case '2-trainer':
      return {
        title: 'Personal Training',
        description:
          'Goal setting, nutrition, workout plans and motivation. Everything you need to be your best self.',
      };
    case '3-environment':
      return {
        title: 'Positive Environment',
        description:
          'Clean, friendly and relaxed environment. No waiting on machines, no waiting on your progress.',
      };

    default:
      return {};
  }
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  > div:not(:first-child):not(:last-child) {
    margin: 0 1rem;
  }

  ${Media.MOBILE`
    flex-direction: column;

    > div:not(:first-child):not(:last-child) {
      margin: 1rem 0;
    }
  `}
`;

// Super hacky, need to change
const Perks = () => {
  // Where we will store the perks
  const [perks, setPerks] = React.useState([]);

  // get all the park images
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)/" }
            relativeDirectory: { eq: "perks" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(quality: 100, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  // If the images change (which they shouldnt), set the perks and images
  React.useEffect(() => {
    const newPerks = [];

    allFile.edges.forEach(({ node }) => {
      // name is the image filename (i.e 1-money)
      const { childImageSharp, name } = node;
      newPerks.push({
        ...getPerkForImageName(name),
        image: childImageSharp.fluid,
      });
    });

    setPerks(newPerks);
  }, [allFile]);

  return (
    <Container>
      {perks.map(perk => (
        <Perk key={perk.title} {...perk} />
      ))}
    </Container>
  );
};

export default Perks;
