import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Media from '../../helpers/mediaTemplates';
import Text from '../Text';
import config from '../../config';

const Container = styled.div`
  width: 300px;
  height: 325px;
  background-color: white;

  ${Media.MOBILE`
    width: 100%;
    height: 275px;
  `}

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

const PerkImage = styled.div`
  height: 175px;
  max-height: 175px;
  width: 100%;
  position: relative;
  z-index: 0;

  > h3 {
    position: absolute;
    bottom: 24px;
    left: 24px;
    z-index: 5;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const LearnMoreLink = styled(Link)`
  text-decoration: none;

  > span {
    :hover {
      color: ${config.colors.alt};
    }
  }
`;

const Perk = ({ image, title, description }) => (
  <Container>
    <PerkImage>
      <Img fluid={image} style={{ height: '100%' }} />
      <Text as="h3" size="medium" weight="bold" color="white">
        {title}
      </Text>
    </PerkImage>
    <Content>
      <Text as="p" size="small" color={config.colors.alt}>
        {description}
      </Text>
      {/*
      <LearnMoreLink to={config.routes.contact}>
        <Text as="span" size="small" color={config.colors.primary}>
          Learn More
        </Text>
      </LearnMoreLink>
      */}
    </Content>
  </Container>
);

export default Perk;
