import React from 'react';
import styled from 'styled-components';

import Media from '../../helpers/mediaTemplates';

import Testimonial from './Testimonial';
import { useStaticQuery, graphql } from 'gatsby';

const getTestimonialForImageName = imageName => {
  switch (imageName) {
    case '1-miranda':
      return {
        name: 'Miranda Gibson Fitness',
        instagram: 'mirandagibsonfitness',
        description: `I signed up in May, came all of the month of May and then didn't come back for like six months. And when I walked in the door, first thing Bill (owner) said to me was "Miranda" and I was like, wow. I don't have a common name and I haven't been here in six months and you remember me. So that just solidified, yes, this is the place for me.`,
      };
    case '2-ryan':
      return {
        name: 'Ryan',
        instagram: 'ryan_brewa',
        description: `The problem with a lot of the bigger gyms is that it's not necessarily a workout environment, but rather an appearance contest and a lot of people come kind of dressed up. But I did not get that impression here. People were here to work out, but at the same time you'd have guys who were 300 pounds who were smiling and coming over to shake my hand. So it was very inclusive.`,
      };
    case '3-devonte':
      return {
        name: 'Devonte',
        instagram: 'devocamps',
        description: `I can develop myself as a person here. I've gotten more confidence just from going to this gym and it feels like a gym where I can see myself staying for a really long time. And I want to stay for a long time.`,
      };
    default:
      return {};
  }
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  > div:not(:first-child):not(:last-child) {
    margin: 0 1rem;
  }

  ${Media.MOBILE`
    flex-direction: column;

    > div:not(:first-child):not(:last-child) {
      margin: 1rem 0;
    }
  `}
`;

const Testimonials = () => {
  // Where we will store the perks
  const [testimonials, setTestimonials] = React.useState([]);

  // get all the park images
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)/" }
            relativeDirectory: { eq: "testimonials" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(height: 100, width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `
  );

  // If the images change (which they shouldnt), set the testimonials and images
  React.useEffect(() => {
    const newTestimonials = [];

    allFile.edges.forEach(({ node }) => {
      // name is the image filename (i.e 1-miranda)
      const { childImageSharp, name } = node;

      newTestimonials.push({
        ...getTestimonialForImageName(name),
        image: childImageSharp.fixed,
      });
    });

    setTestimonials(newTestimonials);
  }, [allFile]);

  return (
    <Container>
      {testimonials.map(testimonial => (
        <Testimonial key={testimonial.name} {...testimonial} />
      ))}
    </Container>
  );
};

export default Testimonials;
