import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Text from '../Text';
import Icon from '../Icon';

import Media from '../../helpers/mediaTemplates';
import config from '../../config';

const Container = styled.div`
  width: 300px;
  height: 400px;
  background-color: white;

  ${Media.MOBILE`
    width: 100%;
    height: 300px;
  `}

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  padding: ${config.sizes.spacing}px;
`;

const Header = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${config.sizes.spacing / 2}px;

  svg {
    margin-left: 50px;
    height: 40px;
    width: 40px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > a {
    text-decoration: none;
    margin-bottom: 5px;
  }
`;

const Testimonial = ({ name, description, image, instagram }) => {
  return (
    <Container>
      <Header>
        <Img fixed={image} style={{ borderRadius: '50px' }} />
        <Icon name="quote" color={config.colors.alt} />
      </Header>
      <Content>
        <a
          href={`https://instagram.com/${instagram}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text as="h2" size="medium" weight="bold">
            {name}
          </Text>
        </a>
        <Text as="p" size="small" color={config.colors.alt}>
          {description}
        </Text>
      </Content>
    </Container>
  );
};

export default Testimonial;
